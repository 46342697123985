<template>
  <transition name="fade">
    <v-dialog
      content-class="dialog-image px-0"
      persistent
      v-model="showDialog"
      max-width="400"
      rounded="xl"
    >
      <v-card style="overflow: visible" class="pb-2" rounded="xl">
        <v-card-title class="reward-title d-flex justify-center">
          <div class="d-flex justify-center">
            <v-img
              class="title-image"
              :src="require('@/assets/island/rainbow.png')"
            ></v-img>
          </div>
        </v-card-title>

        <v-card-text class="reward-body" v-if="showDialog">
          <div class="black--text text-center rounded-xl py-3">
            <div class="text-h5">
              {{ title }}
            </div>
            <div class="d-flex justify-center mt-4 ">
              
              <v-sheet color="transparent" class="text-h5 d-flex flex-column align-center" >
                <v-sheet color="transparent" class="green--text py-2">
                  {{ message }}
                </v-sheet>
                
                <lottie-vue-player
            :src="`https://lottie.host/babd8e9d-2691-4cc3-b215-c58dbba16242/Ti2TEzH5nd.json`"
            :autoplay="true"
            style="width: 60px; height: 60px; background-color: transparent!important; background: none!important;"
          >
          </lottie-vue-player>
                
              </v-sheet>
            </div>
          </div>
          <div class="text-center">
            <v-btn
              @click="close()"
              elevation="2"
              color="yellow"
              class="px-10 text-h6"
            >
              {{ $t("action.ok") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: [
    'title',
    "message",
    "showDialog",
    "callbackClose",
  ],
  components: {
  },
  data: () => ({}),
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    let Sfx = new Audio(
      require("@/assets/sounds/claim.wav")
    );
    Sfx.play();
    Sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
  },
  methods: {
    close() {
      this.callbackClose();
    },
  },
};
</script>
<style>
.dialog-image {
  overflow: visible !important;
}
.reward-title {
  position: relative !important;
  height: 60px !important;
  background-color: #ffc581;
}
.reward-body {
  position: relative !important;
}
.title-image {
  bottom: -30px !important;
  width: 150px !important;
  position: absolute !important;
}
</style>
